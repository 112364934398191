
import { mapState, mapActions } from 'vuex';
import carSweepstakesWidgetForm from './car-sweepstakes-widget-form/car-sweepstakes-widget-form.vue';
import carSweepstakesWidgetResult from './car-sweepstakes-widget-result/car-sweepstakes-widget-result.vue';
import carSweepstakesWidgetOverlay from './car-sweepstakes-widget-overlay/car-sweepstakes-widget-overlay.vue';
export const TYPES = {
	DEFAULT: 'default',
	SEARCH: 'search'
};
export default {
	props: {
		branchAtmPageUrl: {
			required: true
		},
		resultData: {
			required: false,
			type: Object
		},
		type: {
			type: String,
			required: false,
			validator(val) {
				return Object.values(TYPES).includes(val);
			},
			default: TYPES.DEFAULT
		}
	},
	components: {
		'car-sweepstakes-widget-form': carSweepstakesWidgetForm,
		'car-sweepstakes-widget-result': carSweepstakesWidgetResult,
		'car-sweepstakes-widget-overlay': carSweepstakesWidgetOverlay
	},
	watch: {
		resultData: {
			handler(val) {
				this.setResults(val);
				if (val) {
					this.hideForm();
				} else {
					this.reset();
				}
			},
			immediate: true
		}
	},
	computed: {
		...mapState('carSweepstakesWidget', [
			'technicalError',
			'loading',
			'results',
			'popupVisible',
			'formVisible',
			'hasWonCanTake',
			'overThirtyDays',
			'hasWonNotTaken'
		]),
		TYPES: () => TYPES
	},
	methods: {
		hidePopupAndSetFocus() {
			this.hidePopup();
			this.$nextTick(function () {
				if (this.$refs['car-sweepstakes-widget-result'].$refs['check-button']) {
					this.$refs['car-sweepstakes-widget-result'].$refs['check-button'].$refs.button.focus();
				}
			});
		},
		...mapActions('carSweepstakesWidget', ['check', 'showPopup', 'hidePopup', 'reset', 'setResults', 'hideForm'])
	}
};
