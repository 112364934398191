
import { required } from 'vuelidate/lib/validators';
import pattern from '../../../common/js/validators/pattern';
import VueInput from '@ui-common/vue-input';
import VueButton from '@ui-common/vue-button';
import VueTooltip from '@ui-common/vue-tooltip';
import VueErrorMessage from '@ui-common/vue-error-message';
import { TYPES } from '../car-sweepstakes-widget';
export default {
	name: 'carSweepstakesWidgetForm',
	components: {
		VueInput,
		VueButton,
		VueTooltip,
		VueErrorMessage
	},
	props: {
		formVisible: {
			required: true
		},
		type: {
			type: String
		}
	},
	data() {
		return {
			model: {
				series: null,
				number: null
			}
		};
	},
	computed: {
		fullNumber() {
			return this.model.series + this.model.number;
		},
		TYPES: () => TYPES
	},
	watch: {
		formVisible(val) {
			if (val) {
				this.resetForm();
				this.$nextTick(() => {
					this.$refs.series.$refs.input.focus();
				});
			}
		}
	},
	validations() {
		return {
			model: {
				series: {
					required,
					pattern: pattern('^\\d{1,2}$')
				},
				number: {
					required,
					pattern: pattern('^\\d{7}$')
				}
			}
		};
	},
	methods: {
		sendForm() {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.$emit('on-form-sent', this.fullNumber);
			}
		},
		onPaste(e) {
			e.preventDefault();
			// nem szamok kiszurese
			let clipboardData = e.clipboardData || window.clipboardData;
			let pasted = clipboardData.getData('Text').replace(/\D/g, '');
			this.model.series = pasted.substring(0, 2);
			if (pasted.length > 2) {
				this.model.number = pasted.substring(2, 9);
			}
			this.jumpToButton();
		},
		resetForm() {
			this.$v.$reset();
			this.model = {
				series: null,
				number: null
			};
		},
		fillZero() {
			if (this.model.series && this.model.series.length == 1) {
				this.model.series = '0' + this.model.series;
			}
		},
		jumpToNumber() {
			this.$refs.number.$refs.input.focus();
		},
		jumpToButton() {
			this.$refs.button.$refs.button.focus();
		}
	}
};
