
import VueButton from '@ui-common/vue-button';
import { TYPES } from '../car-sweepstakes-widget';
export default {
	data() {
		return {
			canTakeDates: [],
			canTakeCars: [],
			notTakenDates: [],
			hasWonCanTake: false,
			hasWonNotTaken: false,
			overThirtyDays: false
		};
	},
	props: {
		results: {
			required: true
		},
		type: {
			type: String
		}
	},
	components: {
		VueButton
	},
	computed: {
		canTakeDatesJoined() {
			return this.canTakeDates.join(', ');
		},
		canTakeCarsJoined() {
			return this.canTakeCars.join(', ');
		},
		TYPES: () => TYPES,
		isSearch() {
			return this.type == TYPES.SEARCH;
		}
	},
	watch: {
		results: {
			handler(val) {
				this.canTakeDates = [];
				this.canTakeCars = [];
				this.notTakenDates = [];
				this.hasWonCanTake = false;
				this.hasWonNotTaken = false;
				this.overThirtyDays = false;

				if (val) {
					if (val.sweepstakes) {
						if (val.sweepstakes.length) {
							for (let sweepstake of val.sweepstakes) {
								let won = sweepstake;
								if (won.carType) {
									//
									if (won.lotDate) {
										let today = new Date();
										let lotDate = new Date(won.lotDate);
										let timeDiff = Math.abs(lotDate.getTime() - today.getTime());
										let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;
										this.overThirtyDays = diffDays > 30;
										this.hasWonCanTake = !this.overThirtyDays;
										if (this.hasWonCanTake) {
											this.canTakeCars.push(won.carType);
											this.canTakeDates.push(this.$options.filters.date(won.lotDate, 'YYYY. MMMM DD'));
										}
									}
								} else {
									// nem vette át
									this.hasWonNotTaken = true;
									if (won.lotDate) {
										this.notTakenDates.push(this.$options.filters.date(won.lotDate, 'YYYY. MMMM DD'));
									}
								}
							}
							if (!this.isSearch) {
								this.$nextTick(() => {
									this.$refs['check-button'].$refs.button.focus();
								});
							}
							this.$gtm.trackEvent({
								event: 'carPrize',
								carPrizeResult: 'won',
								carPrizeType: 'check',
								accountNumber: val.number
							});
						} else {
							if (!this.isSearch) {
								this.$nextTick(() => {
									this.$refs['try-another'].$refs.button.focus();
								});
							}
							this.$gtm.trackEvent({
								event: 'carPrize',
								carPrizeResult: 'lost',
								carPrizeType: 'check',
								accountNumber: val.number
							});
						}
					}
				}
			},
			immediate: true
		}
	}
};
