
import { TYPES } from '../car-sweepstakes-widget';
export default {
	name: 'carSweepstakesWidgetOverlay',
	data() {
		return {
			stickyTop: null,
			visible: false
		};
	},
	props: {
		results: {
			required: true
		},
		popupVisible: {
			required: true
		},
		branchAtmPageUrl: {
			required: true
		},
		hasWonCanTake: {
			required: true
		},
		overThirtyDays: {
			required: true
		},
		hasWonNotTaken: {
			required: true
		},
		type: {
			type: String
		}
	},
	computed: {
		TYPES: () => TYPES
	}
};
